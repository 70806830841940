.intro {
	display: flex;
	height: 77vh;
	margin-top: 6rem;
}

.intro__left {
	display: flex;
	position: relative;
	gap: 2rem;
	flex: 1;
	flex-direction: column;
}

.intro__name {
	display: flex;
	flex-direction: column;
}

.intro__name>:nth-child(1) {
	color: var(--black);
	font-weight: bold;
	font-size: 3rem;
}

.intro__name>:nth-child(2) {
	color: var(--orange);
	font-weight: bold;
	font-size: 3rem;
}

.intro__name>:nth-child(3) {
	font-weight: 300;
	font-size: 1rem;
	color: var(--gray);
	margin-top: 10px;
}

.intro__button {
	width: 6rem;
	height: 3rem;
	padding: 5px;
}

.intro__icons {
	margin-top: 3rem;
	display: flex;
	gap: 0rem;
}

.intro__icons>* {
	transform: scale(.5);
}

.intro__icons>*:hover {
	cursor: pointer;
}

.intro__right {
	flex: 1;
	position: relative;
}

.intro__right>* {
	position: absolute;
	z-index: 1;
}

.intro__right>:nth-child(1) {
	transform: scale(.8);
	left: -12%;
	top: -9%;
}

.intro__right>:nth-child(2) {
	transform: scale(.7);
	left: -3rem;
	top: -4.6rem;
}

.intro__right>:nth-child(3) {
	width: 80%;
}

.intro__right>:nth-child(4) {
	transform: scale(.6);
	top: -20%;
	left: -25%;
	border-radius: 50%;
	padding: 0;

}

.blur {
	position: absolute;
	width: 22rem;
	height: 14rem;
	border-radius: 50%;
	background: #edd0ff;
	z-index: -9;
	top: -118%;
	left: 56%;
	filter: blur(72px);
}

@media screen and (max-width: 480px) {
	.intro {
		flex-direction: column;
		gap: 7rem;
		height: 64rem;
	}

	.intro__right {
		transform: scale(0.8);
		left: -3rem;
	}

	.intro__right .blur {
		display: none;
	}

	.floating-div:nth-of-type(1) {
		/* display: none; */
		top: -7rem !important;
	}
}
