@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

:root {
  --yellow: #F5C32C;
  --orange: #FCA61F;
  --black: #242D49;
  --gray: #788097;
  --blueCard: #DDF8FE;
  --purple: rgb(238 210 255);
  --boxShadow: 0px 19px 60px rgba(53, 51, 51, 0.18);
  --orangeCard: rgba(252, 166, 31, 0.45);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  list-style-type: none;
}

.App {
  padding: 0.5rem 3.5rem;
  overflow: hidden;
  color: var(--black);
}

.button {
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
}

.button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid var(--orange);
  color: var(--orange);
}

@media screen and (max-width: 480px) {
  .App {
    padding: 0.5rem 1rem;

  }
}
